import { UserService } from './../../services/user-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import './create-offer-dialog.scss';
import { autoinject, bindable, computedFrom, LogManager } from 'aurelia-framework';
import { DesignTypeService } from 'services/design-type-service';
import { CreateOfferDto } from 'models/OfferModels';
import { NetOfferService } from 'services/net-offer-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { ErrorService } from 'services/error-service';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('create-offer-dialogs');

const designOrders = {
  MNCL: 0,
  'MNHC-ST': 1,
  'MNHC-CW': 2,
  'MNCN-F': 3,
  'MNCL-ST': 4,
  MNSQ: 5,
  'MNSQ-CC': 6,
  MNR: 7,
  'MNR-CC': 8,
};

@autoinject
export class CreateOfferDialog {
  @bindable private isCombiNet = false;
  @bindable private isCollect = false;
  @bindable private empty = true;
  private saving = false;
  private dialogOpen = false;
  private designTypes: Array<any>;
  private offerDto: CreateOfferDto = new CreateOfferDto();

  private designTypesWithCombinet = ['MNCL', 'MNCL-C', 'MNCL-ST', 'MNHC-ST'];

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private designTypeService: DesignTypeService,
    private netOfferService: NetOfferService,
    private toastService: ToastService,
    private userService: UserService,
    private router: Router
  ) {}

  async attached() {
    try {
      this.eventAggregator.subscribe('CreateOfferDialogOpen', (bool) => {
        if (bool) {
          this.openDialog();
        } else {
          this.closeDialog();
        }
      });
      const designTypes = await this.designTypeService.getAllCached();
      const designs = designTypes.filter((d) => d.DesignCode && d.DesignCode.length > 0 && d.DesignCode !== 'MNCL-C');
      const orderedDesigns = new Array(designs.length).fill(0);
      for (const design of designs) {
        if (designOrders[design.DesignCode] !== undefined) {
          orderedDesigns[designOrders[design.DesignCode]] = design;
        } else {
          orderedDesigns.push(design);
        }
      }

      this.designTypes = orderedDesigns;

      // consider set based on user preferences later
      this.offerDto.CurrencyId = 1;

      const user = await this.userService.getCurrentUser();
      this.offerDto.ResponsibleUserId = user.Id;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  isCombiNetChanged(newValue, oldValue) {
    if (newValue && !this.designTypeIsCombiNet) {
      this.offerDto.DesignTypeId = null;
    }
    this.offerDto.IsCombiNet = newValue;
  }

  openDialog() {
    this.dialogOpen = true;
    document.querySelector('html').style.overflowY = 'hidden';
  }

  closeDialog() {
    this.dialogOpen = false;
    document.querySelector('html').style.overflowY = null;
  }

  public getDesignTypeIcon(code) {
    try {
      return require('../../images/designTypes/' + code + '.svg');
    } catch (e) {
      if (e.name !== 'Error') throw e;
      return require('../../images/designTypes/MISSING.svg');
    }
  }

  public setCustomer(event) {
    this.offerDto.CustomerId = event.detail.value;
  }

  private isAvailableDesignType(designType) {
    if (this.isCombiNet) {
      logger.debug('designType ', designType);
      if (this.designTypesWithCombinet.find((x) => x == designType.DesignCode)) {
        return true;
      }
      return false;
    }
    return true;
  }

  @computedFrom('offerDto', 'offerDto.CustomerId', 'offerDto.DesignTypeId', 'offerDto.QuoteNumber')
  get isValid() {
    if (
      this.offerDto.CustomerId &&
      this.offerDto.DesignTypeId != undefined &&
      this.offerDto.QuoteNumber &&
      +this.offerDto.NumberOfNets > 0
    ) {
      return true;
    }

    return false;
  }

  private validateIntegerInString(numberInString: string) {
    const number = parseFloat(numberInString);
    return Number.isSafeInteger(number) ? true : false;
  }

  @computedFrom('offerDto', 'offerDto.DesignTypeId')
  get designTypeIsCombiNet() {
    const designType = this.designTypes.find((d) => d.Id === this.offerDto.DesignTypeId);
    if (designType && this.designTypesWithCombinet.find((x) => x == designType.DesignCode)) {
      return true;
    }

    return false;
  }

  private createOffer() {
    if (!this.isValid) {
      this.toastService.showError('offer.missingDetailsBeforeCreate');
      return;
    }
    if (this.offerDto.QuoteIdCRM && !this.validateIntegerInString(this.offerDto.QuoteIdCRM.toString())) {
      this.toastService.showError('offer.quoteIdCRMErrorMsg');
      return;
    }

    this.saving = true;
    let destinationRoute = 'simple-offer-detail';

    const orgDesignTypeId = this.offerDto.DesignTypeId;

    // a dummy designtype with id 0 is used for custom/advanced offers, use this to
    // set the IsSimpleOffer and navigate to correct route after creating the offer
    if (this.offerDto.DesignTypeId === 0) {
      destinationRoute = 'offer-detail';
      this.offerDto.DesignTypeId = null;
      this.offerDto.IsSimpleOffer = false;
    } else {
      this.offerDto.IsSimpleOffer = true;
    }

    this.netOfferService
      .createOffer(this.offerDto)
      .then((res) => {
        this.toastService.showSuccess('offer.created');
        this.router.navigateToRoute(destinationRoute, { Id: res.Id, NetId: res.NetId });
        this.saving = false;
        this.closeDialog();
      })
      .catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);

        this.offerDto.DesignTypeId = orgDesignTypeId;
      });
  }
}
