import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { OfferStatusService } from "services/offer-status-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class offerStatusDetail {
  private offerstatus: Models.OfferStatus;

  constructor(
    private offerStatusService: OfferStatusService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator
  ) {}

  activate(params) {
    this.offerStatusService
      .get(params.Id)
      .then(offerStatus => {
        this.offerstatus = offerStatus;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateOfferStatus() {
    this.offerStatusService
      .put(this.offerstatus, this.offerstatus.Id)
      .then(res => {
        this.eventAggregator.publish("offerStatusListReset", 1);
        this.toastService.showSuccess("offerstatus.updated");
        this.router.navigateToRoute("offer-status-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteOfferStatus() {
    this.offerStatusService
      .delete(this.offerstatus.Id)
      .then(res => {
        this.eventAggregator.publish("offerStatusListReset", 1);
        this.toastService.showSuccess("offerstatus.deleted");
        this.router.navigateToRoute("offer-status-list");
      })
      .catch(err => this.errorService.handleError(err));
  }
  gotoParentView(){
    this.eventAggregator.publish("offerStatusListReset", 1);
    this.router.navigateToRoute("offer-status-list");   
  }
}
